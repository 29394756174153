import {componentsTheme} from './../mui-core';
import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {MyriadProFonts} from 'sharedResources/assets/fonts/MyriadPro';

// FiraSans Variants
const Display = {
  lg: {
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '5.1875rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '5.1875rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '5.1875rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '5.1875rem',
      fontFamily: 'Fira Sans',
    },
  },
  md: {
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '4.3125rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '4.3125rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '4.3125rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '4.3125rem',
      fontFamily: 'Fira Sans',
    },
  },
  sm: {
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '3.5625rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '3.5625rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '3.5625rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '3.5625rem',
      fontFamily: 'Fira Sans',
    },
  },
};
const Header = {
  lg: {
    // variant = h1
    thin: {
      fontWeight: 300,
      fontSize: '3rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      fontSize: '3rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      fontSize: '3rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
  },
  md: {
    // variant = h2
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '2.5rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '2.5rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '2.5rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '2.5rem',
      fontFamily: 'Fira Sans',
    },
  },
  sm: {
    // variant = h3
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '2.0625rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '2.0625rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '2.0625rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '2.0625rem',
      fontFamily: 'Fira Sans',
    },
  },
};
const Title = {
  lg: {
    // variant = h4
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '1.75rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '1.75rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '1.75rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '1.75rem',
      fontFamily: 'Fira Sans',
    },
  },
  md: {
    // variant = h5
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '1.4375rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '1.4375rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '1.4375rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '1.4375rem',
      fontFamily: 'Fira Sans',
    },
  },
  sm: {
    // variant = h6
    thin: {
      fontWeight: 300,
      lineHeight: '125%',
      fontSize: '1.1875rem',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      lineHeight: '125%',
      fontSize: '1.1875rem',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '125%',
      fontSize: '1.1875rem',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      lineHeight: '125%',
      fontSize: '1.1875rem',
      fontFamily: 'Fira Sans',
    },
  },
  xs: {
    thin: {
      fontWeight: 300,
      fontSize: '1rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
    medium: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
    bold: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
    black: {
      fontWeight: 900,
      fontSize: '1rem',
      lineHeight: '125%',
      fontFamily: 'Fira Sans',
    },
  },
};
const Caption = {
  light: {
    fontWeight: 300,
    lineHeight: '150%',
    fontSize: '2.0625rem',
    fontFamily: 'Fira Sans',
  },
  medium: {
    fontWeight: 500,
    lineHeight: '150%',
    fontSize: '2.0625rem',
    fontFamily: 'Fira Sans',
  },
};
const Quote = {
  light: {
    fontWeight: 300,
    lineHeight: '150%',
    fontStyle: 'italic',
    fontSize: '2.0625rem',
    fontFamily: 'Fira Sans',
  },
  medium: {
    fontWeight: 500,
    lineHeight: '150%',
    fontStyle: 'italic',
    fontSize: '2.0625rem',
    fontFamily: 'Fira Sans',
  },
};

// Inter Variants
const Text = {
  xl: {
    regular: {
      fontWeight: 400,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
    italic: {
      fontWeight: 400,
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '1.4375rem',
    },
  },
  lg: {
    regular: {
      fontWeight: 400,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
    italic: {
      fontWeight: 400,
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '1.1875rem',
    },
  },
  md: {
    regular: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '150%',
      fontFamily: 'Inter',
    },
    italic: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
    },
    bold: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '150%',
      fontFamily: 'Inter',
    },
  },
  sm: {
    regular: {
      fontWeight: 400,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '0.8125rem',
    },
    italic: {
      fontWeight: 400,
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
      fontSize: '0.8125rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '0.8125rem',
    },
  },
  xs: {
    regular: {
      fontWeight: 400,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '0.6875rem',
    },
    italic: {
      fontWeight: 400,
      lineHeight: '150%',
      fontStyle: 'italic',
      fontFamily: 'Inter',
      fontSize: '0.6875rem',
    },
    bold: {
      fontWeight: 700,
      lineHeight: '150%',
      fontFamily: 'Inter',
      fontSize: '0.6875rem',
    },
  },
};
const Label = {
  lg: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '150%',
    fontFamily: 'Inter',
  },
  md: {
    fontWeight: 500,
    lineHeight: '150%',
    fontFamily: 'Inter',
    fontSize: '0.8125rem',
  },
  sm: {
    fontWeight: 500,
    lineHeight: '150%',
    fontFamily: 'Inter',
    fontSize: '0.6825rem',
  },
  xs: {
    fontWeight: 500,
    lineHeight: '150%',
    fontSize: '0.563rem',
    fontFamily: 'Inter',
  },
};

const getAppTheme = mode => {
  const theme = createTheme({
    palette: {
      mode,
      background: {
        main: '#F1F1F4',
        dark: '#000000',
        white: '#fff',
      },
      primary: {
        main: '#635BFF',
        dark: '#4E36F5',
      },
      secondary: {
        main: '#a956e3',
        light: '#A28DE7',
      },
      success: {
        main: '#98BF0E',
        light: '#81c784',
        dark: '#388e3c',
        contrastText: '#fff',
      },
      error: {
        main: '#b71c1c', // '#DB3024',
        light: '#d32f2f',
        dark: '#b71c1c',
        contrastText: '#fff',
      },
      grey: {
        main: '#667085',
        contrastText: '#fff',
      },
      darkGray: {
        main: '#333333',
        contrastText: '#fff',
      },
      midGray: {
        main: '#a0a4b5',
        contrastText: '#fff',
      },
      grayLight: {
        main: '#F5F5F5',
      },
      grayBorder: {
        main: '#D8D8D8',
      },
      dialogDarkBG: {
        main: '#181818',
        contrastText: '#000',
      },
      lightViolet: {
        light: '#E5E6FF',
        main: '#7b60df4d',
        contrastText: '#fff',
      },
      purpleBG: {
        main: '#f3f2fc',
      },
      taleBlue: {
        main: '#06AED4',
        contrastText: '#000',
      },
      tale400: {
        main: '#2ED3B7',
        contrastText: '#000',
      },
      grey800: {
        main: '#424242',
        contrastText: '#fff',
      },
      greyLight800: {
        main: '#182230',
        contrastText: '#fff',
      },
      backgroundL1: {
        main: '#F9FAFB',
        contrastText: '#000',
      },
      text: {
        primary: '#212636',
        contrastText: '#fff',
      },
    },
    components: {
      ...componentsTheme.components,
      MuiButton: {
        styleOverrides: {
          root: ({ownerState}) => ({
            borderRadius: '8px',
            fontFamily: 'MyriadPro',
            width: ownerState.width,
            textTransform: 'capitalize',
            variants: [
              {
                props: {
                  color: 'primary',
                },
                style: {
                  color: '#635BFF',
                },
              },
              {
                props: {
                  disabled: false,
                  color: 'primary',
                  variant: 'contained',
                },
                style: {
                  color: '#fff',
                  border: '1px solid #4E36F5',
                  boxShadow: '0px 1px 2px 0px #00000014',
                  background: 'linear-gradient(#635BFF, #4E36F5)',
                },
              },
              {
                props: {
                  color: 'primary',
                  variant: 'outlined',
                },
                style: {
                  color: '#635BFF',
                  border: '1px solid #635BFF80',
                  boxShadow: '0px 1px 2px 0px #00000014',
                },
              },
              {
                props: {
                  size: 'medium',
                },
                style: {
                  fontSize: '15px',
                  minHeight: '40px',
                  padding: '4px 12px',
                  lineHeight: '24px',
                },
              },
              {
                props: {
                  size: 'small',
                },
                style: {
                  fontSize: '12px',
                },
              },
              {
                props: {
                  size: 'large',
                },
                style: {
                  fontSize: '16px',
                },
              },
            ],
          }),
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'MyriadPro';
          src: url('${MyriadProFonts.REGULAR.OTF}');
          src: url('${MyriadProFonts.REGULAR.OTF}') format('opentype');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'MyriadProLight';
          src: url('${MyriadProFonts.LIGHT.OTF}');
          src: url('${MyriadProFonts.LIGHT.OTF}') format('opentype');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'MyriadProSemiBold';
          src: url('${MyriadProFonts.SEMIBOLD.OTF}');
          src: url('${MyriadProFonts.SEMIBOLD.OTF}') format('opentype');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'MyriadProBold';
          src: url('${MyriadProFonts.BOLD.OTF}');
          src: url('${MyriadProFonts.BOLD.OTF}') format('opentype');
          font-weight: 600;
          font-style: normal;
          font-display: swap;
        }
        /* Scrollbar styles */
        &::-webkit-scrollbar {
          width: 4px !important;
          height: 4px !important;
          background-color: rgba(123, 96, 223, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #0367FF !important;
          border-radius: 14px;
        }
    `,
      },
    },
    typography: {
      fontFamily: 'MyriadPro',
      h1: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '40px',
      },
      h2: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '27px',
      },
      h3: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '23px',
      },
      h4: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '20px',
      },
      h5: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '18px',
      },
      h6: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '16px',
      },
      // custom typography
      h7: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '14px',
      },
      body1: {
        fontSize: '14px',
      },
      body2: {
        fontSize: '12px',
      },
      bodyL: {
        fontSize: '16px',
      },
      bodyXL: {
        fontSize: '18px',
      },
      subtitle1: {
        fontFamily: 'MyriadProSemiBold',
        fontSize: '16px',
      },
      subtitle2: {
        fontSize: '15px',
      },
      caption: {
        fontSize: '13px',
      },
      metricNo: {
        fontSize: '45px',
        fontFamily: 'MyriadProLight',
      },
      pageTitle: {
        fontSize: '50px',
        fontFamily: 'MyriadProBold',
      },
      pageDesc: {
        fontSize: '17px',
      },
      popupCaptions: {
        fontSize: '30px',
        fontFamily: 'MyriadProSemiBold',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1400,
      },
    },
  });
  // Design System Typography Variants
  // Display
  theme.typography['display-lg-thin'] = {
    ...Display.lg.thin,
    [theme.breakpoints.down('lg')]: {
      fontSize: '4.3125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5625rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  };
  theme.typography['display-lg-medium'] = {
    ...Display.lg.medium,
    [theme.breakpoints.down('lg')]: {
      fontSize: '4.3125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5625rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  };
  theme.typography['display-lg-bold'] = {
    ...Display.lg.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '4.3125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5625rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  };
  theme.typography['display-lg-black'] = {
    ...Display.lg.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '4.3125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3.5625rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  };
  theme.typography['display-md-thin'] = {
    ...Display.md.thin,
    [theme.breakpoints.down('lg')]: {
      fontSize: '3.5625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  };
  theme.typography['display-md-medium'] = {
    ...Display.md.medium,
    [theme.breakpoints.down('lg')]: {
      fontSize: '3.5625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  };
  theme.typography['display-md-bold'] = {
    ...Display.md.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '3.5625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4375rem',
    },
  };
  theme.typography['display-md-black'] = {
    ...Display.md.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '3.5625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  };
  theme.typography['display-sm-thin'] = {
    ...Display.sm.thin,
    [theme.breakpoints.down('lg')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0625rem',
    },
  };
  theme.typography['display-sm-medium'] = {
    ...Display.sm.medium,
    [theme.breakpoints.down('lg')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0625rem',
    },
  };
  theme.typography['display-sm-bold'] = {
    ...Display.sm.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0625rem',
    },
  };
  theme.typography['display-sm-black'] = {
    ...Display.sm.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0625rem',
    },
  };

  // Header
  theme.typography['header-lg-thin'] = {
    ...Header.lg.thin,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.0625rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  };
  theme.typography['header-lg-medium'] = {
    ...Header.lg.medium,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.0625rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  };
  theme.typography['header-lg-bold'] = {
    ...Header.lg.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.0625rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  };
  theme.typography['header-lg-black'] = {
    ...Header.lg.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.0625rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  };
  theme.typography['header-md-thin'] = {
    ...Header.md.thin,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.0625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4375rem',
    },
  };
  theme.typography['header-md-medium'] = {
    ...Header.md.medium,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.0625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4375rem',
    },
  };
  theme.typography['header-md-bold'] = {
    ...Header.md.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.0625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1875rem',
    },
  };
  theme.typography['header-md-black'] = {
    ...Header.md.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.0625rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4375rem',
    },
  };
  theme.typography['header-sm-thin'] = {
    ...Header.sm.thin,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4375rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1875rem',
    },
  };
  theme.typography['header-sm-medium'] = {
    ...Header.sm.medium,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4375rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1875rem',
    },
  };
  theme.typography['header-sm-bold'] = {
    ...Header.sm.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4375rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1875rem',
    },
  };
  theme.typography['header-sm-black'] = {
    ...Header.sm.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4375rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1875rem',
    },
  };

  // Title
  theme.typography['title-lg-thin'] = {
    ...Title.lg.thin,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.4375rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  };
  theme.typography['title-lg-medium'] = {
    ...Title.lg.medium,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.4375rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  };
  theme.typography['title-lg-bold'] = {
    ...Title.lg.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.4375rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  };
  theme.typography['title-lg-black'] = {
    ...Title.lg.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.4375rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  };
  theme.typography['title-md-thin'] = {
    ...Title.md.thin,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['title-md-medium'] = {
    ...Title.md.medium,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['title-md-bold'] = {
    ...Title.md.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  };
  theme.typography['title-md-black'] = {
    ...Title.md.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['title-sm-thin'] = {
    ...Title.sm.thin,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['title-sm-medium'] = {
    ...Title.sm.medium,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['title-sm-bold'] = {
    ...Title.sm.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['title-sm-black'] = {
    ...Title.sm.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['title-xs-thin'] = {
    ...Title.xs.thin,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['title-xs-bold'] = {
    ...Title.xs.bold,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['title-xs-black'] = {
    ...Title.xs.black,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['title-xs-medium'] = {
    ...Title.xs.medium,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };

  // Text
  theme.typography['text-xl-regular'] = {
    ...Text.xl.regular,
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['text-xl-italic'] = {
    ...Text.xl.italic,
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['text-xl-bold'] = {
    ...Text.xl.bold,
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.1875rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['text-lg-regular'] = {
    ...Text.lg.regular,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['text-lg-italic'] = {
    ...Text.lg.italic,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['text-lg-bold'] = {
    ...Text.lg.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8125rem',
    },
  };
  theme.typography['text-md-regular'] = {
    ...Text.md.regular,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.8125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['text-md-italic'] = {
    ...Text.md.italic,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.8125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['text-md-bold'] = {
    ...Text.md.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.8125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['text-sm-regular'] = {
    ...Text.sm.regular,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['text-sm-italic'] = {
    ...Text.sm.italic,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['text-sm-bold'] = {
    ...Text.sm.bold,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['text-xs-bold'] = {...Text.xs.bold};
  theme.typography['text-xs-italic'] = {...Text.xs.italic};
  theme.typography['text-xs-regular'] = {...Text.xs.regular};

  // Label
  theme.typography['label-lg'] = {
    ...Label.lg,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.8125rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['label-md'] = {
    ...Label.md,
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.6875rem',
    },
  };
  theme.typography['label-sm'] = {
    ...Label.sm,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.563rem',
    },
  };
  theme.typography['label-xs'] = {...Label.xs};

  // Quote
  theme.typography['quote-light'] = {
    ...Quote.light,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4375rem',
    },
  };
  theme.typography['quote-medium'] = {
    ...Quote.medium,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4375rem',
    },
  };

  // Caption
  theme.typography['caption-light'] = {
    ...Caption.light,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4375rem',
    },
  };
  theme.typography['caption-medium'] = {
    ...Caption.medium,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4375rem',
    },
  };
  return responsiveFontSizes(theme);
};

export {getAppTheme};
