import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  List,
  Stack,
  Avatar,
  Drawer,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  goEducateLogo, // white logo
  goEducateLogoColored,
  GradientGoeducateLogo,
} from 'sharedResources/assets/images';
import SideBarMenuItem from './SideBarMenuItem';
import {getCloudinaryImage} from 'mui-core/utils';
import {userPortalSelector} from 'redux/modules/auth';
import {styled, useTheme} from '@mui/material/styles';
import BackgroundPaper from 'mui-core/BackgroundPaper';
import {getAppTheme, toggleAppDrawer} from 'redux/modules/general';

const openedMixin = (theme, drawerWidth) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const SideDrawer = styled(Drawer, {shouldForwardProp: prop => prop !== 'open'})(
  ({theme, open, drawerWidth}) => ({
    flexShrink: 0,
    width: drawerWidth,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, drawerWidth),
      '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const AppSidebar = ({
  drawerWidth,
  sideBarMenu,
  onLogoClick,
  DrawerHeader,
  instanceDetails,
  SideBarButtonMenu,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userPortal = useSelector(userPortalSelector) || {};
  const {openMenuBar = true} = useSelector(getAppTheme) || {};
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {logo_cloudinary, name: company_name} = instanceDetails || {};
  let userName = company_name || '';
  if (userPortal === 'super_user') {
    userName = 'Super User Portal';
  }
  const userLogo =
    getCloudinaryImage(logo_cloudinary, 'logo') || GradientGoeducateLogo;

  useEffect(
    () => {
      dispatch(toggleAppDrawer(isMobile ? false : true));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile]
  );

  return (
    <SideDrawer
      open={openMenuBar}
      variant="permanent"
      drawerWidth={drawerWidth}>
      <BackgroundPaper
        background={{
          light: 'white',
          dark: theme.palette.darkGray.main,
        }}
        sx={{minHeight: '100%'}}>
        <DrawerHeader>
          <Stack
            sx={[
              {
                gap: 3,
                alignItems: 'center',
                py: {
                  sm: 2,
                },
              },
              openMenuBar
                ? {
                    py: {
                      xs: 2,
                    },
                  }
                : {
                    py: {
                      xs: 1,
                    },
                  },
            ]}>
            <Box onClick={onLogoClick} sx={{cursor: 'pointer'}}>
              <img
                width={180}
                alt="GoEducate-logo"
                height={{xs: 36, sm: 48}}
                src={
                  theme.palette.mode === 'dark'
                    ? goEducateLogo
                    : goEducateLogoColored
                }
              />
            </Box>
            <Avatar
              src={userLogo}
              sx={[
                {
                  transition: '0.3s all ease-in',
                  '& img': {
                    objectFit: 'contain',
                  },
                },
                openMenuBar
                  ? {
                      width: 100,
                      height: 100,
                    }
                  : {
                      width: 50,
                      height: 50,
                    },
              ]}
            />
            {openMenuBar && (
              <Typography
                variant="text-md-bold"
                sx={[
                  {
                    textAlign: 'center',
                    whiteSpace: 'initial',
                    color: 'text.primary',
                    transition: '0.3s all ease-in',
                  },
                  openMenuBar
                    ? {
                        opacity: 1,
                      }
                    : {
                        opacity: 0,
                      },
                ]}>
                {userName}
              </Typography>
            )}
          </Stack>
        </DrawerHeader>
        <Divider variant="middle" sx={{mb: 2}} />
        {SideBarButtonMenu && openMenuBar && (
          <>
            <SideBarButtonMenu />
            <Divider variant="middle" sx={{my: 2}} />
          </>
        )}
        <List>
          {sideBarMenu &&
            Array.isArray(sideBarMenu) &&
            sideBarMenu.map(menuItem => (
              <SideBarMenuItem
                key={menuItem?.key}
                menuItem={menuItem}
                sideBarOpen={openMenuBar}
              />
            ))}
        </List>
      </BackgroundPaper>
    </SideDrawer>
  );
};

export default AppSidebar;
