import * as TYPES from './constants';
import {
  requestCareerNavUsers,
  receiveCareerNavUsers,
  careerNavUsersError,
} from './actions';
import {getLoggedInstituteUUID} from '../auth';
import {API, JSON_HEADER, JWT_HEADER} from 'config/srm.config';

const initialState = {
  data: null,
  request: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST_CAREERNAV_USERS:
      return {
        ...state,
        request: true,
      };
    case TYPES.RECEIVE_CAREERNAV_USERS:
      return {
        ...state,
        data: action.payload || null,
        request: false,
        error: false,
      };
    case TYPES.CAREERNAV_USERS_ERROR:
      return {
        ...state,
        error: true,
        request: false,
      };
    default:
      return state;
  }
};

export const fetchCareerNavUsers = (page, pageSize, filters, sort = '') => (
  dispatch,
  getState
) => {
  const {
    auth: {
      access: {token},
    },
  } = getState();
  let updatedFilters = '';
  if (filters?.length) {
    filters.map(newItem => {
      updatedFilters += `&${newItem.field}=${newItem.value}`;
      return `&${newItem.field}=${newItem.value}`;
    });
  }
  if (sort) {
    updatedFilters += `&sort=${sort}`;
  }

  dispatch(requestCareerNavUsers());
  const endpoint = API.student.get_students;
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
    cache: 'default',
  };
  try {
    const institute_uuid = getLoggedInstituteUUID(getState());
    let queryParams = `&page_size=${pageSize}&page=${page}`;
    queryParams += updatedFilters ? updatedFilters : '';
    return fetch(
      `${endpoint}?institute_uuid=${institute_uuid}${queryParams}`,
      config
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Api Fails');
      })
      .then(res => {
        dispatch(receiveCareerNavUsers(res));
      })
      .catch(err => {
        console.error(err);
        dispatch(careerNavUsersError(err));
      });
  } catch (err) {
    console.error(err);
    dispatch(careerNavUsersError(err));
  }
};
