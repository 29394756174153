export const SRM_ROUTES = {
  HOME: {DASHBOARD: '/', EDIT_SCHOOL_PROFILE: 'edit/school/profile'},
  CAREERTRACKER: {DASHBOARD: '/careerTracker'},
  ADMIN: {
    USER_MANAGEMENT: '/user/management',
    ADD_USER: '/adduser',
    SYSTEM_MANAGEMENT: '/system/management',
    STUDENT_MANAGEMENT: '/student/management',
    PERMISSIONS: '/permissions/admin',
    COURSE_MANAGEMENT: '/course/management',
  },
  OUTREACH: {CALENDAR_EVENTS: '/outreach/calendar/events', EVENTS: '/events'},
  PATHWAYS: {
    DASHBOARD: '/pathways/dashboard',
    PATH_LIST: '/pathways/paths-list/paths',
    CREATE_PATH: '/create/path',
  },
  PARTNERS: {
    DASHBOARD: '/partners/dashboard',
    PARTNERS_LIST: '/partners/partners-list/partners',
  },
  STRONGWORKFORCE: {DASHBOARD: '/strong-workforce'},
  INSTITUTION: {CREATE: '/institution/profile'},
  REPORTS: {DASHBOARD: '/reports', CREATE_REPORTS: 'reports/create'},
};
