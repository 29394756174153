import {getBase, getXToken} from 'mui-core/domain-services';
import {SRM_ROUTES} from './routes';
import {
  AccountCircleOutlined,
  HandshakeOutlined,
  Insights,
  LibraryBooksOutlined,
  RocketLaunchOutlined,
  Route,
  Settings,
} from '@mui/icons-material';

const SRM = process.env.REACT_APP_SRM;
const GPS = process.env.REACT_APP_GPS;
const PROGRAM = process.env.REACT_APP_PROGRAM;
const QUICK_FYND = process.env.REACT_APP_QUICK_FYND;
const SRMDOMAIN = process.env.REACT_APP_SRMDOMAIN;
const SMS_NUMBER = process.env.REACT_APP_SMS_NUMBER;
const NOTIFICATIONS_SETTINGS = process.env.REACT_APP_NOTIFICATIONS_SETTINGS;
const STUDENT_PROFILE = process.env.REACT_APP_STUDENT_PROFILE;

export const GPS_EVENT =
  process.env.REACT_APP_ENV === 'prod'
    ? 'https://goeducate.com/events'
    : process.env.REACT_APP_GPS_EVENT;
export const GPS_URL = process.env.REACT_APP_GPS_URL;
export const CURRENT_ENV = process.env.REACT_APP_ENV;

// environment specific settings for GPS domain
// qa,dev,uat = qa-v2., dev-v2., uat-v2.
// demo = demo.
// prod = not required, hence ''
let domainPrefix = CURRENT_ENV;
if (CURRENT_ENV === 'prod') {
  domainPrefix = '';
} else {
  domainPrefix += '.';
}

export const GPS_DOMAIN = domainPrefix + process.env.REACT_APP_GPS_DOMAIN;
export const S3_URL = process.env.REACT_APP_S3;
export const CDN_URL = process.env.REACT_APP_SRM_CDN;
export const HELP_WIDGET_ID = process.env.REACT_APP_HELP_WIDGET_ID;

export const COLLEGEADMINEMAIL = process.env.REACT_APP_COLLEGEADMINEMAIL;
export const IMG_CLOUDINARY =
  process.env.REACT_APP_CLOUDINARY_API_GET + 'image/upload/q_auto';

// Policy documents paths
export const TERMS = `${CDN_URL}privacy/GoEducate_Terms_of_Use_2020.pdf`;

export const CURRENT_DATE = new Date();

export const ROUTES = {
  outreach: 'outreach',
  program: 'program',
  admin: 'admin',
};

export const imgFormat = ['image/jpeg', 'image/png'];

export const NOTIFICATION_TYPES = {
  NEWCOMMUNITY: 'new_community',
  ALERTME: 'alert_me',
  ALERTCOMMUNITY: 'alert_community',
  SURVEYRESPONSE: 'survey_response',
};

export const ValidFieldsLength = {
  title: 150,
  description: 2500,
};

export const JSON_HEADER = {
  'Content-Type': 'application/json',
  ...getXToken(),
};
export const AUTH_HEADER = token => ({Authorization: `Token ${token}`});
export const JWT_HEADER = token => ({Authorization: `Bearer ${token}`});
export const SUBSCRIPTION_HEADER = token => ({
  'Ocp-Apim-Subscription-Key': `${token}`,
});

export const API = {
  auth: {
    token: `${SRM}token/`,
    refresh: `${SRM}refresh-token/`,
    resetPassword: `${SRM}user_forgot_password/`,
    forceResetPassword: `${SRM}user_reset_password/`,
    confirmForgetPassword: `${SRM}user_confirm_forgot_password/`,
    state: `${GPS}state/`, //admin
    user: `${SRM}user/`,
    userPermissions: `${SRM}user_type_premission/`,
    add_user: `${SRM}add_user/`,
    update_user: `${SRM}user/`,
    delete_user: `${SRM}delete_user/`,
    access_roles: `${SRM}module/`,
    user_roles: `${SRM}user_roles/`,
    logo: `${SRM}logo/`,
    institution_type: `${SRM}institution_type/`,
    users: `${SRM}user/`,
    changePassword: `${SRM}user/profile/`,
    license: `${SRM}gps/license`,
  },
  institution: {
    faculty: `${SRM}faculty/`,
    faculty_type: `${SRM}faculty_type/`,
    institution: `${
      SRM //institution: `${SRM}institution/`,
    }institute/`,
    institution_list: `${SRM}gps/institute/`,
    institute_timeline: `${SRM}institute-timeline/`,
    institution_type: `${SRM}institute_type/`,
    department: `${
      SRM //institution_type: `${SRM}institution_type/`,
    }department/`,
    term: `${SRM}term/`,
    message_staff: `${SRM}message_staff/`,
    dashboard: `${SRM}srm_metrics`,
  },
  student: {
    student_onboard_statistics: `${GPS}srm/student-pathway/?student_details=true`,
    get_students: `${GPS}v2/srm/student_management/`,
    // get_students: `${GPS}v2/srm/student-pathway/`,
    student_onboard_events: `${GPS}student_events`,
    student_profile_details: `${GPS}v2/student_profile`,
    course_map: `${SRM}gps/course-map`,
    paths: `${GPS}srm/student-paths`,
    student_assessment: `${GPS}student-assessment`,
    student_award_type: `${GPS}student_award_type`,
    studentSkills: `${
      GPS //student_assessment: `${GPS}student-sk`,
    }student-sk/`,
    skillsData: `${GPS}skills/`,
    student_profile: `${
      GPS //student_profile: `${GPS}student-profile/`,
    }myprofile/`,
    student_profile_data: `${STUDENT_PROFILE}user/user-profile/`,
    download_student_asset: `${GPS}v1/download-student-asset`,
    student_pathDetails: `${GPS}myprofile/`,
    programs: `${SRM}business_partner/program/sign-up/`,
    jobs: `${SRM}business_partner/jobs/apply/`,
    subscriptions: `${SRM}subscribe/`,
    student_survey: `${SRM}student-survey/`,
    cluster_types: `${SRM}gps/cluster-v2`,
    pathway_planner: `${SRM}pathway-planner/`,
    student_cis: `${GPS}v2/student_cis/`,
    student_active_paths: `${GPS}v2/student_onboard`,
    student_saved_paths: `${GPS}v2/save_path`,
    student_compared_paths: `${GPS}v2/compare_path`,
    student_summery: `${GPS}v2/student_details`,
    student_opportunity: `${GPS}v2/bp_opportunity_apply`,
    student_credentials: `${GPS}v2/credential`,
    download_resume: `${GPS}public_student_resume`,
    student_resume_list: `${GPS}public_student_resume`,
    public_privacy_settings: `${GPS}v2/public_privacy_settings`,
  },
  outreach: {
    server: 'outreach',
    event: `${SRM}outreach/v1/event/`,
    event_activity: `${SRM}outreach/v1/event-activity/`,
    eventSurvey: `${SRM}gps/outreach/v1/event-detail/`,
    lead_map: `${SRM}outreach/v1/event-student-list/`,
    staff_map: `${SRM}staff_map/`,
    demographic: `${
      SRM //demographic: `${SRM}demographic_request/`,
    }demographic_request_type/`,
    request_type: `${SRM}info_request_type/`,
    lead_msg: `${SRM}message_leads/`,
    event_msg: `${SRM}outreach/v1/event-send-message/`,
    notification_settings: `${
      NOTIFICATIONS_SETTINGS //notification_settings: `${SRM}notification/v1/notification_settings/`,
    }notificaion-settings/`,
    notifications: `${NOTIFICATIONS_SETTINGS}/user-notifications/d6ac0213-2447-47da-948a-4ae889b90f18/`,
    event_attachment: `${
      SRM //notifications: `${SRM}notification/v1/notifications/`,
    }outreach/v1/event-attachment/`,
    event_banner: `${SRM}outreach/v1/event-banner/`,
    lead_contact_data: `${SRM}lead_contact_data/`,
    event_status_overview: `${
      SRM /////// new APIs
    }outreach/v1/event_status_overview`,
    sms_number: `${SMS_NUMBER}`,
    send_audience_msg: `${SRM}outreach/v1/audience-send-message/`,
  },
  program: {
    server: 'program',
    program: `${PROGRAM}program/`,
    requirement: `${PROGRAM}requirement/`,
    student_program: `${PROGRAM}student_program/`,
    student_requirement: `${PROGRAM}student_requirement/`,
    service_program: `${PROGRAM}service_program/`,
    achievement_program: `${PROGRAM}achievement_program/`,
    programs_enrollment: `${SRM}programs-enrollment/`,
  },
  pathway: {
    tempToken: '7630fec08a3ef5d194c84e8436c62a9d6c7e9493',
    secret: '61241fe289414fd3b9ae144362d486ed',
    path_name: `${SRM}path-type-name/`,
    del_path_attachment: `${SRM}del-path-attachment/`,
    add_milestone_attachment: `${SRM}add-milestone-attachment/`,
    cluster: `${SRM}cluster/`,
    cluster_type: `${SRM}cluster-type/`,
    career_path: `${
      SRM //career_path: `${SRM}career-path/`,
    }program_to_institue/`,
    courses: `${SRM}courses/`,
    course_type: `${SRM}course-type/`,
    program_duration: `${SRM}program-duration/`,
    acheivement_options: `${SRM}acheivement-options/`,
    milestone: `${SRM}milestone/`,
    milestone_type: `${SRM}milestone-type/`,
    publish: `${SRM}publish-v1/`,
    college_path_search: `${SRM}collegepath-search`,
    general_education_path: `${SRM}gened/`,
    general_education_search: `${SRM}gepath-search/`,
    status: `${SRM}status/`,
    filter_paths: `${SRM}filter-paths/`,
    onboarding_path: `${SRM}onboarding-path/`,
    onboarding_type: `${SRM}onboarding-type/`,
    program_paths: `${QUICK_FYND}program_paths?`,
    course_search: `${QUICK_FYND}course?`,
    //enrolled_student: `${GPS}srm/get-studentlist-enrolledfor-path`,
    enrolled_student: `${GPS}v2/srm/student_list_enrolled_for_path`,
    program: `${SRM}gps/program`,
  },
  users: {
    institution_users: `${SRM}v1/super-user/institute-users/`,
    institution: `${SRM}v1/super-user/institute/`,
    user: `${SRM}user`,
    user_resend_temp_password: `${SRM}user_resend_temp_password`,
    user_type: `${GPS}user_type`,
  },
  srm: {
    server: 'srm',
    account: `${SRM}account/`,
    search_user: `${SRM}user/`,
    picture: `${SRM}user/picture/`,
    user_details: `${SRM}user/`,
    demographic: `${SRM}demographic/`,
    user_account: `${SRM}auth_user/`, // department: `${SRM}department/`, // faculty: `${SRM}faculty/`,
    //account_type: `${SRM}account_type/`,
    account_type: `${SRM}user_type/`,
    institute_type_permission: `${SRM}institute_type_permission/`,
    user_type_premission: `${SRM}user_type_premission/`,
    user_type: `${SRM}user_type/`,
    subdomain_license: `${SRM}gps/license/`,
  },
  partners: {
    partner_dashboard_data: `${SRM}partner-dashboard-data/`,
    partner_list: `${SRM}partner-list/`,
    partner: `${SRM}partner/`,
  },
  strong_workforce: {
    student_data: `${SRM}strong-workforce/student-data/`,
    survey_details: `${SRM}strong-workforce/survey-details/`,
    institution_term_data: `${SRM}strong-workforce/institution_terms`,
    submit_survey: `${SRM}strong-workforce/create-survey/`,
    programs: `${SRM}strong-workforce/list-of-programs/`,
  },
  course_management: {
    courses: `${SRM}courses/`,
    course_credit_status: `${SRM}credit-status/`,
    course_instruction_type: `${SRM}course-instruction-type/`,
    course_transfer_type: `${SRM}course-transfer-type/`,
    skills: `${SRM}skills/`,
    catalog: `${SRM}catalog/`,
  },
  reports: {
    report_objects: `${SRM}report-objects`,
    report_run: `${SRM}report-run`,
    report: `${SRM}reports`,
  },
  tinymceEditor: {
    uploadimage: `${SRM}upload_images`,
  },
};

export const noOfAdminCardInPage = 12;

export const eventTypes = {
  'in-person': 'In-Person',
  virtual: 'Virtual',
};
export const eventAlert = {
  None: '1',
  'Same Time': '0',
  '5 Minutes Before': '-300',
  '10 Minutes Before': '-600',
  '15 Minutes Before': '-900',
  '30 Minutes Before': '-1800',
  '1 Hour Before': '-3600',
  '2 Hours Before': '-7200',
  '1 Day Before': '-86400',
  '2 Days Before': '-172800',
  '5 days before': '-432000',
  '7 days before': '-604800',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthsShortNames = {
  Jan: 0,
  Feb: 0,
  Mar: 0,
  Apr: 0,
  May: 0,
  Jun: 0,
  Jul: 0,
  Aug: 0,
  Sep: 0,
  Oct: 0,
  Nov: 0,
  Dec: 0,
};

export const userTypePermissions = [
  'College District Administrator',
  'College Administrator',
  'HS District Administrator',
  'HS Administrator',
  'GA Administrator',
  'AEP Administrator',
  'HS Administrator',
  'Business Administrator',
  'Business Partner Administrator',
  'Adult Education School Administrator',
  'super_user',
];

export const notificationSettingsData = [
  {
    title: 'New Event',
    data: [
      {
        label: 'From Community',
        key: 'alert_from_community',
      },
    ],
  },
  {
    title: 'Event Alerts',
    data: [
      {
        label: 'From Me',
        key: 'alert_for_me',
      },
      {
        label: 'From Community',
        key: 'new_from_community',
      },
    ],
  },
  {
    title: 'Event Responses',
    data: [
      {
        label: 'Survey Responses',
        key: 'response_from_survey',
      },
    ],
  },
];

export const defautHomePage = '/';

export const profileMenu = [
  {
    title: 'Edit Profile',
    key: 'edit_profile',
  },
  {
    title: 'Change Password',
    key: 'change_password',
  },
  {
    title: 'Notification Settings',
    key: 'notifications',
  },
  {
    title: 'About Us',
    key: 'about_us',
  },
  {
    title: 'Log Out',
    key: 'log_out',
  },
];

export const sideBarMenu = [
  {
    key: 'home',
    Icon: Insights,
    title: 'Dashboard',
    path: SRM_ROUTES.HOME.DASHBOARD,
  },
  {
    key: 'career-tracker',
    title: 'Career Tracker',
    Icon: Route,
    module: 'Career Tracker',
    path: SRM_ROUTES.CAREERTRACKER.DASHBOARD,
  },
  {
    title: 'Administration',
    Icon: AccountCircleOutlined,
    key: 'administration',
    submenus: [
      {
        title: 'User management',
        path: SRM_ROUTES.ADMIN.USER_MANAGEMENT,
        // Icon: 'User Management',
        key: 'user_management',
      },
      {
        title: 'Student management',
        path: SRM_ROUTES.ADMIN.STUDENT_MANAGEMENT,
        module: 'Student Management',
        key: 'student_management',
      },
      {
        title: 'Course management',
        path: SRM_ROUTES.ADMIN.COURSE_MANAGEMENT,
        module: 'Course Management',
        key: 'course_management',
      },
    ],
  },
  {
    title: 'Outreach',
    Icon: RocketLaunchOutlined,
    key: 'outreach',
    submenus: [
      {
        title: 'Calendar',
        path: SRM_ROUTES.OUTREACH.CALENDAR_EVENTS,
        key: 'outreach_calendar',
        module: 'Events',
      },
      {
        title: 'Events List',
        path: SRM_ROUTES.OUTREACH.EVENTS,
        key: 'outreach_event_list',
        module: 'Events',
      },
    ],
  },
  {
    title: 'Programs',
    Icon: LibraryBooksOutlined,
    key: 'pathways',
    path: SRM_ROUTES.PATHWAYS.DASHBOARD,
  },
  {
    title: 'Partners',
    Icon: HandshakeOutlined,
    key: 'partners',
    submenus: [
      {
        title: 'Dashboard',
        path: SRM_ROUTES.PARTNERS.DASHBOARD,
        key: 'partners_dashboard',
      },
      {
        title: 'List of Partners',
        path: SRM_ROUTES.PARTNERS.PARTNERS_LIST,
        key: 'partners_list',
      },
    ],
  },
  {
    title: 'Institution Settings',
    Icon: Settings,
    key: 'settings',
    path: SRM_ROUTES.INSTITUTION.CREATE,
  },
];

export const homePageMenu = [
  {
    title: 'Home',
    imgSrc: 'icon-Home icon',
    module: 'Home',
    key: 'dashboard',
    mainMenu: true,
    path: SRM_ROUTES.HOME.DASHBOARD,
  },
  {
    mainMenu: true,
    key: 'career-tracker',
    title: 'Career Tracker',
    imgSrc: 'icon-Home icon',
    module: 'Career Tracker',
    path: SRM_ROUTES.CAREERTRACKER.DASHBOARD,
  },
  {
    title: 'Administration',
    imgSrc: 'icon-Profile icon',
    key: 'administration',
    content: [
      {
        submenuTitle: 'User management',
        path: SRM_ROUTES.ADMIN.USER_MANAGEMENT,
        module: 'User Management',
        key: 'user_management',
      },
      // {
      //   submenuTitle: 'System management',
      //   path: SRM_ROUTES.ADMIN.SYSTEM_MANAGEMENT,
      //   key: 'system_management',
      // },
      {
        submenuTitle: 'Student management',
        path: SRM_ROUTES.ADMIN.STUDENT_MANAGEMENT,
        module: 'Student Management',
        key: 'student_management',
      }, //   submenuTitle: 'Permissions', // {
      //   path: SRM_ROUTES.ADMIN.PERMISSIONS,
      //   key: 'permissions',
      // },
      {
        submenuTitle: 'Course management',
        path: SRM_ROUTES.ADMIN.COURSE_MANAGEMENT,
        module: 'Course Management',
        key: 'course_management',
      },
    ],
  },
  {
    title: 'Outreach',
    imgSrc: 'icon-outreach-new icon',
    key: 'outreach',
    content: [
      {
        submenuTitle: 'Calendar',
        path: SRM_ROUTES.OUTREACH.CALENDAR_EVENTS,
        key: 'outreach_calendar',
        module: 'Events',
      },
      {
        submenuTitle: 'Events List',
        path: SRM_ROUTES.OUTREACH.EVENTS,
        key: 'outreach_event_list',
        module: 'Events',
      },
    ],
  },
  {
    title: 'Pathways',
    imgSrc: 'icon-pathways icon',
    key: 'pathways',
    mainMenu: true,
    module: 'Programs',
    path: SRM_ROUTES.PATHWAYS.DASHBOARD,
    // content: [
    //   {
    //     submenuTitle: 'Dashboard',
    //     key: 'pathways_dashboard',
    //   },
    //   {
    //     submenuTitle: 'List of Paths',
    //     path: SRM_ROUTES.PATHWAYS.PATH_LIST,
    //     key: 'pathways_paths',
    //   },
    // ],
  },
  {
    title: 'Partners',
    imgSrc: 'icon-partners icon',
    key: 'partners',
    content: [
      {
        submenuTitle: 'Dashboard',
        path: SRM_ROUTES.PARTNERS.DASHBOARD,
        key: 'partners_dashboard',
        module: 'Partners',
      },
      {
        submenuTitle: 'List of Partners',
        path: SRM_ROUTES.PARTNERS.PARTNERS_LIST,
        key: 'partners_list',
        module: 'Partners',
      },
    ],
  },
  // {
  //   title: 'Strong Workforce',
  //   imgSrc: 'icon-swf icon',
  //   key: 'strong_workforce',
  //   mainMenu: true,
  //   path: SRM_ROUTES.STRONGWORKFORCE.DASHBOARD,
  // },
  // {
  //   title: 'Reports',
  //   imgSrc: 'icon-reports icon',
  //   key: 'reports',
  //   mainMenu: true,
  //   path: SRM_ROUTES.REPORTS.DASHBOARD,
  // },
];

export const fixedRespondedFields = [
  {
    label: 'First Name',
    fieldKey: 'first_name',
  },
  {
    label: 'Last Name',
    fieldKey: 'last_name',
  },
  {
    label: 'Email',
    fieldKey: 'email',
  },
  {
    label: 'Mobile',
    fieldKey: 'phone',
  },
];

export const optionalRespondedFields = [
  {
    type: 'gender',
    name: 'Gender',
  },
  {
    type: 'dob',
    name: 'Date of Birth',
  },
  {
    type: 'ethnicity',
    name: 'Race/Ethnicity',
  },
  {
    type: 'native_language',
    name: 'Native Language',
  },
];

export const PORTAL = {
  CRIMINAL_JUSTICE: 'criminal_justice',
};
