import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PathIcon = ({...props}) => {
  return (
    <SvgIcon {...props}>
      <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="Gauge">
          <path
            d="M18.75 15.75C18.0849 15.7506 17.4388 15.9718 16.9129 16.379C16.387 16.7862 16.011 17.3563 15.8438 18H6.75C5.95435 18 5.19129 17.6839 4.62868 17.1213C4.06607 16.5587 3.75 15.7956 3.75 15C3.75 14.2044 4.06607 13.4413 4.62868 12.8787C5.19129 12.3161 5.95435 12 6.75 12H15.75C16.7446 12 17.6984 11.6049 18.4017 10.9017C19.1049 10.1984 19.5 9.24456 19.5 8.25C19.5 7.25544 19.1049 6.30161 18.4017 5.59835C17.6984 4.89509 16.7446 4.5 15.75 4.5H6.75C6.55109 4.5 6.36032 4.57902 6.21967 4.71967C6.07902 4.86032 6 5.05109 6 5.25C6 5.44891 6.07902 5.63968 6.21967 5.78033C6.36032 5.92098 6.55109 6 6.75 6H15.75C16.3467 6 16.919 6.23705 17.341 6.65901C17.7629 7.08097 18 7.65326 18 8.25C18 8.84674 17.7629 9.41903 17.341 9.84099C16.919 10.2629 16.3467 10.5 15.75 10.5H6.75C5.55653 10.5 4.41193 10.9741 3.56802 11.818C2.72411 12.6619 2.25 13.8065 2.25 15C2.25 16.1935 2.72411 17.3381 3.56802 18.182C4.41193 19.0259 5.55653 19.5 6.75 19.5H15.8438C15.984 20.0432 16.2738 20.5362 16.6802 20.923C17.0866 21.3098 17.5933 21.5749 18.1427 21.6882C18.6922 21.8015 19.2625 21.7584 19.7887 21.5639C20.3149 21.3694 20.7761 21.0312 21.1197 20.5877C21.4634 20.1443 21.6758 19.6133 21.7329 19.0552C21.79 18.4971 21.6894 17.9341 21.4426 17.4303C21.1958 16.9265 20.8127 16.5019 20.3367 16.2049C19.8608 15.9079 19.311 15.7503 18.75 15.75ZM18.75 20.25C18.4533 20.25 18.1633 20.162 17.9166 19.9972C17.67 19.8324 17.4777 19.5981 17.3642 19.324C17.2506 19.0499 17.2209 18.7483 17.2788 18.4574C17.3367 18.1664 17.4796 17.8991 17.6893 17.6893C17.8991 17.4796 18.1664 17.3367 18.4574 17.2788C18.7483 17.2209 19.0499 17.2506 19.324 17.3642C19.5981 17.4777 19.8324 17.67 19.9972 17.9166C20.162 18.1633 20.25 18.4533 20.25 18.75C20.25 19.1478 20.092 19.5294 19.8107 19.8107C19.5294 20.092 19.1478 20.25 18.75 20.25Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default PathIcon;
