import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GaugeIcon = ({...props}) => {
  return (
    <SvgIcon {...props}>
      <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="Gauge">
          <path
            id="Vector"
            d="M19.4119 7.56285C18.4397 6.58846 17.2842 5.81617 16.0121 5.29049C14.74 4.76482 13.3764 4.49617 12 4.50004H11.9625C6.19406 4.51973 1.5 9.28129 1.5 15.106V17.25C1.5 17.6479 1.65804 18.0294 1.93934 18.3107C2.22064 18.592 2.60218 18.75 3 18.75H21C21.3978 18.75 21.7794 18.592 22.0607 18.3107C22.342 18.0294 22.5 17.6479 22.5 17.25V15C22.5039 13.6176 22.2328 12.2482 21.7027 10.9714C21.1725 9.69461 20.3938 8.53598 19.4119 7.56285ZM21 17.25H11.2228L16.3566 10.1907C16.4737 10.0298 16.5221 9.82899 16.4911 9.63243C16.4602 9.43587 16.3524 9.25965 16.1916 9.14254C16.0307 9.02543 15.8299 8.97702 15.6333 9.00797C15.4368 9.03891 15.2605 9.14667 15.1434 9.30754L9.3675 17.25H3V15.106C3 14.8172 3.01406 14.5322 3.04031 14.25H5.25C5.44891 14.25 5.63968 14.171 5.78033 14.0304C5.92098 13.8897 6 13.699 6 13.5C6 13.3011 5.92098 13.1104 5.78033 12.9697C5.63968 12.8291 5.44891 12.75 5.25 12.75H3.30656C4.27406 9.10692 7.43156 6.35254 11.25 6.03192V8.25004C11.25 8.44895 11.329 8.63972 11.4697 8.78037C11.6103 8.92102 11.8011 9.00004 12 9.00004C12.1989 9.00004 12.3897 8.92102 12.5303 8.78037C12.671 8.63972 12.75 8.44895 12.75 8.25004V6.03098C14.6106 6.18756 16.3767 6.91813 17.8042 8.12175C19.2316 9.32537 20.2501 10.9426 20.7188 12.75H18.75C18.5511 12.75 18.3603 12.8291 18.2197 12.9697C18.079 13.1104 18 13.3011 18 13.5C18 13.699 18.079 13.8897 18.2197 14.0304C18.3603 14.171 18.5511 14.25 18.75 14.25H20.9691C20.9888 14.4985 21 14.7479 21 15V17.25Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default GaugeIcon;
