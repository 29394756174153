import jwtDecode from 'jwt-decode';
import {createSelector} from 'reselect';

import * as TYPES from 'redux/types';

import {logout, recieveInstance} from './actions';

import {
  API,
  JSON_HEADER,
  JWT_HEADER,
  userTypePermissions,
} from 'config/srm.config';
import {API as SUPER_USER_API} from 'config/superuser.config';

import {refreshAccessToken} from './actions';

const initialState = {
  access: undefined,
  refresh: undefined,
  forcePassword: undefined,
  error: '',
  request: false,
  isOnline: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.AUTH_REQUEST:
    case TYPES.TOKEN_REQUEST:
      return {
        ...state,
        request: true,
      };
    case TYPES.AUTH_SUCCESS:
      // can be used for future
      // userTypePermissions.includes(
      //   action.payload.user_type
      // );
      const allowAccess = true;
      return {
        ...state,
        request: false,
        forcePassword: undefined,
        loggedinUserId: action.payload.srm_user,
        institutionType: action.payload.institution_type,
        userType: action.payload.user_type,
        user_portal: action.payload.user_portal,
        UIPermissions: action.payload.UIPermissions,
        permissions: action.payload.permissions,
        institution_id:
          (action.payload.institution_id &&
            parseInt(action.payload.institution_id)) ||
          null,
        access: {
          token: action.payload.access,
          bodyToken: action.payload.token,
          'custom:institution_uuid': action.payload.institution_uuid,
          ...jwtDecode(action.payload.access),
        },

        refresh: {
          token: action.payload.refresh,
          // ...jwtDecode(action.payload.refresh),
        },
      };
    case TYPES.REFRESH_ACCESS_TOKEN:
      return {
        ...state,
        access: {
          token: action.payload.access,
          bodyToken: action.payload.token,
          'custom:institution_uuid': action.payload.institution_uuid,
          ...jwtDecode(action.payload.access),
        },
        refresh: {
          token: action.payload.refresh,
        },
      };
    case TYPES.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        forcePassword: {
          userName: action.payload.userName,
          session: action.payload.Session,
        },
      };
    case TYPES.TOKEN_RECEIVED:
      if (action.payload.srm_user) {
        return {
          ...state,
          [action.payload.server]: action.payload.token,
          srm_user_uuid: action.payload.srm_user,
        };
      } else {
        return {
          ...state,
          [action.payload.server]: action.payload.token,
        };
      }
    case TYPES.RECEIVE_MODULE_LIST:
      return {
        ...state,
        modules: action.modules,
      };
    case TYPES.RECEIVE_USER_PERMISSIONS:
      return {
        ...state,
        loggedInUserPermissions: action.userPermissions,
      };
    case TYPES.RECEIVE_USER_WITH_ROLES_LIST:
      return {
        ...state,
        user_roles: action.user_roles,
      };
    case TYPES.RECEIVE_LOGO:
      return {
        ...state,
        collegeInformation: action.collegeInfo,
      };
    case TYPES.UPDATE_USER_WITH_ROLES_LIST:
      const updatedUserRoles = Object.assign(
        {},
        state['user_roles'],
        action.user_roles
      );
      return {
        ...state,
        user_roles: updatedUserRoles,
      };
    case TYPES.REQUEST_MODULE_LIST_ERROR:
    case TYPES.AUTH_ERROR:
    case TYPES.TOKEN_FAILURE:
      return {
        ...state,
        error: action.msg,
        request: false,
      };
    case TYPES.APP_STATUS:
      return {
        ...state,
        isOnline: action.status,
      };
    case TYPES.SIGNOUT:
      return initialState;
    default:
      return state;
  }
};

const handleError = msg => ({
  type: TYPES.REQUEST_MODULE_LIST_ERROR,
  msg,
});
const receiveModulesList = modules => ({
  type: TYPES.RECEIVE_MODULE_LIST,
  modules,
});
const receiveUserWithRolesList = userRoles => ({
  type: TYPES.RECEIVE_USER_WITH_ROLES_LIST,
  user_roles: userRoles && userRoles.user,
});
const receiveUserPermissions = userPermissions => ({
  type: TYPES.RECEIVE_USER_PERMISSIONS,
  userPermissions,
});
const receiveCollegeInfo = json => ({
  type: TYPES.RECEIVE_LOGO,
  collegeInfo: json,
});

export const collegeInformationSelector = state =>
  state.user &&
  state.user[state.user.show] &&
  state.user[state.user.show].institution_id;

const allInstitutionSelector = state =>
  state.institution && state.institution.data;

export const institutionsRequestSelector = state =>
  state.institution && state.institution.request;

export const collegeInformationFromIdSelector = createSelector(
  collegeInformationSelector,
  allInstitutionSelector,
  (institute, allInstitutions) => {
    return (
      allInstitutions &&
      allInstitutions.filter(institution => institution.id == institute)[0]
    );
  }
);

export const appLogout = instanceData => dispatch => {
  dispatch(logout());
  if (instanceData) dispatch(recieveInstance(instanceData));
};

export const fetchNewAuthToken = callback => (dispatch, getState) => {
  const {
    auth: {
      user_portal = null,
      access = {},
      access: {exp = null} = {},
      refresh: {token = null} = {},
      loggedinUserId = null,
    },
  } = getState();

  let USER_ID = loggedinUserId;

  if (!USER_ID) {
    USER_ID = access['cognito:username'];
  }

  if (!token || !USER_ID) {
    callback();
    return;
  }

  if (token && USER_ID) {
    const config = {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      headers: Object.assign({}, JSON_HEADER),
      body: JSON.stringify(
        Object.assign({}, {user_uuid: USER_ID}, {refresh_token: token})
      ),
    };
    try {
      // check for super user ---
      let endPoint = `${API.auth.refresh}`;
      if (user_portal === 'super_user') {
        endPoint = `${SUPER_USER_API.auth.refresh_token}`;
      }
      return fetch(endPoint, config)
        .then(response => response.json())
        .then(json => {
          dispatch(refreshAccessToken(json));
        })
        .catch(err => {
          console.log(err);
          dispatch(logout());
        });
    } catch (err) {
      console.log(err);
      dispatch(logout());
    }
  }
};

export const fetchAllCollegeInfo = token => (dispatch, getState) => {
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  try {
    return fetch(API.auth.logo, config)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveCollegeInfo(json));
      });
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchAllModules = token => (dispatch, getState) => {
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  try {
    return fetch(API.auth.access_roles, config)
      .then(response => response.json())
      .then(json => {
        if (json.detail) {
          dispatch(handleError(json.detail));
        } else {
          dispatch(receiveModulesList(json));
        }
      });
  } catch (err) {
    dispatch(handleError(err));
  }
};
export const fetchLoggedinUserPermissions = token => (dispatch, getState) => {
  const {
    auth: {userType},
  } = getState();

  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  try {
    return fetch(
      `${API.auth.userPermissions}?user_type__name=${userType}`,
      config
    )
      .then(response => response.json())
      .then(json => {
        if (json.detail) {
          dispatch(handleError(json.detail));
        } else {
          dispatch(receiveUserPermissions(json));
        }
      });
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchAllUserWithRoles = token => (dispatch, getState) => {
  const config = {
    method: 'GET',
    mode: 'cors',
    headers: Object.assign({}, JWT_HEADER(token), JSON_HEADER),
  };
  try {
    return fetch(API.auth.user_roles, config)
      .then(response => response.json())
      .then(json => {
        if (json.detail) {
          dispatch(handleError(json.detail));
        } else {
          dispatch(receiveUserWithRolesList(json));
        }
      });
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const updateUserRoles = userRoleObj => {
  return {
    type: TYPES.UPDATE_USER_WITH_ROLES_LIST,
    user_roles: userRoleObj,
  };
};

export const outreachTokenSelector = ({auth: {outreach: token}}) => token;
export const srmTokenSelector = ({auth: {srm}}) => srm;
export const authToken = ({auth = {}}) => auth;
export const accessToken = ({auth: {access = {}} = {}}) =>
  access && access.token;
export const bodyToken = ({auth: {access}}) =>
  access ? access.bodyToken : null;
export const refreshToken = ({auth: {refresh = {}} = {}}) =>
  refresh && refresh.token;
export const institutionTokenSelector = ({auth: {institution}}) => institution;
export const getAllModulesSelector = ({auth: {modules}}) => modules;
export const getUserModulePermissions = ({
  auth: {
    access: {module_access},
  },
}) => module_access;

export const getUserWithRoles = ({auth: {user_roles}}) => user_roles;

export const isAccessTokenExpired = ({access: {exp = null} = {}}) =>
  exp && 1000 * exp - new Date().getTime() < 60000;

export const sessionExpiryTime = ({access}) =>
  access && access.exp && 1000 * access.exp - new Date().getTime() - 300000;

export const isRefreshTokenExpired = ({refresh}) =>
  refresh && refresh.exp && 1000 * refresh.exp - new Date().getTime() < 5000;

export const isAuthenticated = state => !isRefreshTokenExpired(state);

export const isOnline = state => state && state.auth && state.auth.isOnline;

export const isAuthRequest = state => state && state.auth && state.auth.request;

export const getLoggedInInstitutionUUID = state =>
  (state &&
    state.auth &&
    state.auth.access &&
    state.auth.access['custom:institution_uuid']) ||
  state.auth['institution_uuid'];

export const getLoggedInPartnerUUID = state =>
  state &&
  state.auth &&
  state.auth.access &&
  state.auth.access['custom:partner_uuid'];

export const errors = ({auth: {error}}) => error;
export const userSelector = state => state.user;
export const userTypeSelector = state => state.auth.userType;
export const userPortalSelector = state => state.auth.user_portal;
export const userManagementPermissionSelector = state =>
  state.auth &&
  state.auth.UIPermissions &&
  state.auth.UIPermissions.userManagement;
export const eventManagementPermissionSelector = state =>
  state.auth &&
  state.auth.UIPermissions &&
  state.auth.UIPermissions.eventManagement;

export const pathwaysPermissionSelector = state =>
  state.auth && state.auth.UIPermissions && state.auth.UIPermissions.pathways;

export const partnersPermissionSelector = state =>
  state.auth && state.auth.UIPermissions && state.auth.UIPermissions.partners;

export const modulePermissions = state => state.auth.permissions;

export const modulePermissionSelector = createSelector(
  modulePermissions,
  modulePermissions => {
    const ModPermissions = ['administration'];
    modulePermissions &&
      modulePermissions.filter(modPermission => {
        if (modPermission.is_enabled) {
          const modName = modPermission.name
            .split(' ')
            .join('_')
            .toLowerCase();
          ModPermissions.push(modName);
        }
      });
    if (ModPermissions.includes('outreach')) {
      ModPermissions.push.apply(ModPermissions, [
        'outreach_calendar',
        'outreach_event_list',
      ]);
    }
    if (ModPermissions.includes('pathways')) {
      ModPermissions.push.apply(ModPermissions, [
        'pathways_paths',
        'pathways_dashboard',
      ]);
    }
    if (ModPermissions.includes('partners')) {
      ModPermissions.push.apply(ModPermissions, [
        'partners_dashboard',
        'partners_list',
      ]);
    }

    return ModPermissions;
  }
);

export const getLoggedInUserDetails = state => state.user;

export const getLoggedInstituteUUID = state => {
  const {
    user,
    auth: {loggedinUserId},
  } = state;
  const usr = (loggedinUserId && user ? user[loggedinUserId] : null) || {};
  return usr ? usr.institution_uuid : null;
};
